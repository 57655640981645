const $elements = [...document.querySelectorAll('.scroll-to')];

$elements.forEach(el => {
  el.addEventListener('click', evt => {
    evt.preventDefault();
    let href = el.getAttribute('href');

    if (href.substring(0, 1) === '/') {
      href = href.substring(1);
    }

    const target = document.getElementById(href.substring(1));
    target.scrollIntoView({
      behavior: 'smooth'
    });
  });
});