const $navbarBurgers = [...document.querySelectorAll('.navbar-burger')];
const navbarScrollSwitch = 100;
const navbar = document.querySelector('.navbar');

if ($navbarBurgers.length > 0) {
  $navbarBurgers.forEach(el => {
    el.addEventListener('click', () => {
      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');
    });
  });

  document.querySelectorAll('.navbar-item').forEach(el => el.addEventListener('click', () => {
    document.querySelector('.navbar-burger').classList.toggle('is-active');
    document.querySelector('.navbar-menu').classList.toggle('is-active');
  }))
}

window.addEventListener('scroll', () => reevalNavbar());

function reevalNavbar() {
  if (window.scrollY >= navbarScrollSwitch) {
    navbar.classList.add('is-floating');
  } else {
    navbar.classList.remove('is-floating');
  }
}

reevalNavbar();