// import 'lazysizes';
import 'picturefill';
import './navbar';
import './scrollto';
import '../scss/main.scss';
import baguetteBox from 'baguettebox.js';
import Swiper from 'swiper';

baguetteBox.run('.gallery');
new Swiper('.swiper-container', {
  loop: true,
  spaceBetween: 0,
  slidesPerView: 1,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 50,
    },
  }
});
